import React from "react"
import Reusable from "../../components/articleLayout"
import XLMIcon from "../../images/xlm.png"
import XLMCard from "../../images/TwitterCards/XLMCard.png"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const StellarXLM50 = () => (
  <Layout>
    <SEO
      title="Earn $50 XLM on Coinbase"
      description="Learn how to earn $50 worth of XLM on Coinbase."
      article={true}
      image={XLMCard}
      pathname="/crypto/stellar-xlm-$50"
    />
    <Reusable
      CoinbaseRef={true}
      Logo={XLMIcon}
      ImageAlt="XLM Logo"
      From="www.coinbase.com"
      Title="Earn $50 worth of Stellar XLM"
      Name="Stellar"
      Ticker="XLM"
      Value="$50"
      Address="https://coinbase.com/earn/xlm/invite/msq8ngfy"
      Step1="Make sure you are signed into your Coinbase account in a separate tab."
      Step3="Click the “Claim Now” button on this page."
      Step4="You will be redirected to Coinbase, accept the invite. (Depending on supply, you may be put on a waitlist. If you are, you will receive an email from Coinbase when it’s your turn.)"
      Step5="Watch Lesson 1, answer the question, receive $2 worth of XLM."
      Step6="Watch Lesson 2, answer the question, receive $2 worth of XLM."
      Step7="Watch Lesson 3, answer the question, receive $2 worth of XLM."
      Step8="Watch Lesson 4, answer the question, receive $2 worth of XLM."
      Step9="Watch Lesson 5, answer the question, receive $2 worth of XLM."
      Step10="Now share your invite link with 4 friends and get an additional $10 for each friend that joins."
      Step11="Congratulations! You just earned $50 worth of Stellar XLM."
      Introduction="Coinbase Earn is a new program offered by Coinbase to their users. They have a variety of coins to choose from with the potential of earning up to $186 in cryptocurrency. Most of their courses are simple; watch a short video, answer a question and get rewarded instantly. Not a bad way to add to your portfolio."
    />
  </Layout>
)

export default StellarXLM50
